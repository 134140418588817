import FasterBilling, { FasterConfiguration } from 'faster-billing-sdk-js'
import config from '../config'

const { productId, environment, authenticator, appVersion } = config
const configuration = new FasterConfiguration(productId, environment, authenticator, { appVersion })

FasterBilling.configure(configuration)

class FasterBillingWrapper {
  constructor() {
    this.billing = null
  }

  start() {
    this.billing = FasterBilling.start()
  }

  get integration() {
    return this.billing.integration
  }

  get authentication() {
    return this.billing.authentication
  }
}

export default new FasterBillingWrapper()
