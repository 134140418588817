<template>
  <div class="dropdown">
    <div class="dropdown__notch" :class="{'dropdown__item--active': activeItem == 0}"></div>
    <div
      v-for="(item, index) in items" :key="index" @mouseover="activeItem = index" @mouseleave="activeItem = -1"
      class="dropdown__item" :class="{'dropdown__item--active': index == activeItem}" @click="$emit('click', $event, index)">
      <div class="dropdown__item__text">
        <p v-t="item.text" />
      </div>
      <component :is="item.icon"
        :class="{
        'dropdown__item__icon--fill': item.fill,
        'dropdown__item__icon--stroke': item.stroke,
        'dropdown__item__icon--fill--active': item.fill && index == activeItem,
        'dropdown__item__icon--stroke--active': item.stroke && index == activeItem,
        }"/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'dropdown',
  props: {
    items: Array
  },
  data() {
    return {
      activeItem: -1
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/styles/core/settings';

.dropdown {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  width: 160px;
  margin-right: $gap / 2;
  box-shadow: 0 2px 6px map-get($colors-custom, 'purple-muted');

  &__notch {
    position: absolute;
    background: #fff;
    transform: rotate(45deg);
    transform-origin: center center;
    width: calc(15px * 2 / 1.4142);
    height: calc(15px * 2 / 1.4142);
    top: -7px;
    right: 20px;
    z-index: 1;
    box-shadow: 0 2px 6px map-get($colors-custom, 'purple-muted');
  }

  &__item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 40px;
    padding: 8px;
    color: map-get($colors-custom, 'purple-dark');
    background: #fff;
    z-index: 2;
    cursor: pointer;

    &--active {
      background-color: map-get($colors-custom, 'blue-light');
    }

    &__text {
      font: {
        weight: 700;
        size: 1.1rem
      }
    }

    &__icon {
      &--fill {
        path {
          fill: map-get($colors-custom, 'purple-dark');
        }

        &--active {
          path {
            fill: #fff;
          }
        }
      }

      &--stroke {
        path {
          stroke: map-get($colors-custom, 'purple-dark');
        }

        &--active {
          path {
            stroke: #fff;
          }
        }
      }
    }
  }
}
</style>
