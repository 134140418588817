<template>
  <div class="layout-lite">
    <div class="reset-password">
      <background/>
      <logo class="reset-password__logo" />
      <boxGeneric borderless :title="title" :subtitle="subtitle" :state="state">
        <template v-slot:default>
          <form class="form" @submit.prevent="change">
            <PKInputText
              v-model="password.new"
              :label-name="'passwordReset.labels.newPassword'"
              :placeholder="'passwordReset.placeholder'"
              :type="'password'"
              :autofocus="true"
              :errorText="'passwordReset.errors.size'"
              :parentValidation="isPasswordRegexValid"
              @validated="forcePasswordMatchValidation"/>

            <PKInputText
              v-model="password.confirm"
              :label-name="'passwordReset.labels.confirmPassword'"
              :type="'password'"
              :errorText="'passwordReset.errors.mismatch'"
              :shouldValidate="isPasswordRegexValid"
              :parentValidation="doesPasswordMatch"
              ref="confirmationInput"/>

            <pkbutton :name="'passwordReset.buttons.save'" :disabled="isChangeDisabled" :loading="loading"/>
          </form>
        </template>
      </boxGeneric>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import Background from '@/components/Background'
import Logo from '@/components/Logo'
import Pkbutton from '@/components/PKButton'
import BoxGeneric from '@/components/BoxGeneric'
import PKInputText from '@/components/PKInputText.vue'

const titles = {
  default: 'passwordReset.titles.default',
  success: 'passwordReset.titles.success',
  error: 'passwordReset.titles.error'
}

const subtitles = {
  default: 'passwordReset.subtitles.default',
  success: 'passwordReset.subtitles.success',
  error: 'passwordReset.subtitles.error'
}

const passwordRegex = /^.{6,15}$/

export default {
  name: 'passwordReset',
  components: {
    Background,
    Logo,
    Pkbutton,
    BoxGeneric,
    PKInputText
  },
  data: () => ({
    actionToken: '',
    password: {
      new: '',
      confirm: ''
    },
    state: {
      isSuccess: false,
      isError: false
    },
    loading: false,
    title: titles.default,
    subtitle: subtitles.default
  }),
  methods: {
    ...mapActions({
      changePassword: 'password/change'
    }),
    forcePasswordMatchValidation: function() {
      if (this.isPasswordRegexValid && this.password.confirm.length > 0) {
        this.$refs.confirmationInput.updateValidation()
      }
    },
    async change() {
      if (this.isChangeDisabled) {
        return
      }
      try {
        this.loading = true
        await this.changePassword({
          actionToken: this.actionToken,
          newPassword: this.password.new
        })
        this.loading = false
        this.title = titles.success
        this.subtitle = subtitles.success
        this.state.isSuccess = true
      } catch (err) {
        this.loading = false
        this.state.isError = true
        this.title = titles.error
        this.subtitle = subtitles.error
      }
    }
  },
  computed: {
    isChangeDisabled: function() {
      if (this.isPasswordRegexValid && this.doesPasswordMatch) {
        this.forcePasswordMatchValidation()
        return false
      }
      return true
    },
    isPasswordRegexValid: function() {
      return passwordRegex.test(this.password.new)
    },
    doesPasswordMatch: function() {
      return this.password.new === this.password.confirm
    }
  },
  mounted() {
    this.actionToken = this.$route.query.actionToken
  }
}
</script>

<style lang="scss">
@import '~@/styles/core/settings';
@import '~@/styles/core/tools';

.reset-password {
  .box-form {
    margin: auto;
    margin-top: 50px;
    margin-bottom: 50px;
    position: relative;
    max-width: 500px;

    .form {
      display: block;
    }
  }

  &__logo {
    margin-top: 50px;
    z-index: 1;
    position: relative;
  }

  // Responsiveness
  @include media(map-get($breakpoints, 'medium'), 'max-height') {
    &__logo {
      display: none;
    }
  }

  @include media(map-get($breakpoints, 'small'), 'max-height') {
    @media (orientation: landscape) {
      .box-form {
        margin-top: 0;
        margin-bottom: 0;
        padding: 25px 30px;
        background: none;

        .form {
          margin-top: 16px;
        }

        .button {
          margin-top: 20px;
        }

        &--password {
          .form {
            margin-top: 0;
          }
        }
      }
    }
  }

  @include media('365px', 'max-height') {
    @media (orientation: landscape) {
      .box-form {
        padding: 5px 30px;

        .button {
          height: 50px;
        }
      }
    }
  }
}
</style>
