import analytics from '../../analytics'
import { uuid } from 'vue-uuid'

export default class WebEmitter {
  constructor() {
    this.screenSequence = 0
    this.requestId = null
  }

  signIn(success = true, failureReason = null) {
    analytics.emit('sign_in', {
      credentialType: 'email',
      success: success,
      failureReason: failureReason
    })
  }

  signOut() {
    analytics.emit('sign_out')
  }

  screen(name) {
    analytics.emit('screen', {
      screenName: name,
      sequence: ++this.screenSequence,
      orientation: 'horizontal'
    })
  }

  iapCheckout(params) {
    this.requestId = uuid.v4()
    analytics.emit('iap_checkout', {
      requestId: this.requestId,
      ...params
    })
  }

  iapRequest(params) {
    analytics.emit('iap_request', {
      requestId: this.requestId,
      ...params
    })
  }

  iapCompleted(params) {
    analytics.emit('iap_completed', {
      requestId: this.requestId,
      ...params
    })
    this.requestId = null
  }
}
