import FasterAccount, { FasterConfiguration } from 'faster-account-sdk-js'
import config from '../config'

const { applicationKey, applicationSecret, appVersion } = config
const configuration = new FasterConfiguration(applicationKey, applicationSecret, { appVersion })

FasterAccount.configure(configuration)

class FasterAccountWrapper {
  constructor() {
    this.account = null
  }

  start() {
    this.account = FasterAccount.start()
  }

  get authSession() {
    return this.account.authSession
  }

  get userSession() {
    return this.account.userSession
  }

  get auth() {
    return this.account.auth
  }

  get credentials() {
    return this.account.credentials
  }

  get email() {
    return this.account.email
  }
}

export default new FasterAccountWrapper()
