import FasterEvent from '../fasterEvent'

export default new FasterEvent(
  { id: 'sign_in', revision: 4 },
  (eventBuilder, parameters) => {
    return eventBuilder
      .dimension('credentialType', parameters.credentialType)
      .dimension('success', parameters.success)
      .dimension('failureReason', parameters.failureReason)
      .build()
  }
)
