<template>
    <div class="background"></div>
</template>

<script>
export default {
  name: 'background'
}
</script>

<style lang="scss">
@import '~@/styles/core/settings';

.background {
  background-image: url("~@/assets/bg-pattern.svg");
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: map-get($colors-custom, 'purple');
  left: 0;
  top: 0;
  z-index: -1;
}
</style>
