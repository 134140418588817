import FasterEvent from '../fasterEvent'

export default new FasterEvent(
  { id: 'update', revision: 0 },
  (eventBuilder, parameters) => {
    return eventBuilder
      .dimension('lastAppVersion', parameters.lastAppVersion)
      .dimension('currentAppVersion', parameters.currentAppVersion)
      .build()
  }
)
