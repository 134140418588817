<template>
  <div class="pk-input-text">
    <label class="form__label" v-t="labelName"/>
    <input
      class="form__input"
      :class="{'form__input--error': shouldValidate && !isValid}"
      :placeholder="$t(placeholder)"
      :type="type"
      :autofocus="autofocus"
      @input="$emit('input', $event.target.value)"
      v-on:blur="updateValidation"
    />
    <p class="form__validation-message form__validation-message--error"  v-if="shouldValidate && !isValid" v-t="errorText" />
  </div>
</template>

<script>
export default {
  name: 'pkInputText',

  props: {
    value: String,
    labelName: String,
    placeholder: String,
    type: {
      Type: String,
      default: 'text'
    },
    autofocus: Boolean,
    errorText: String,
    shouldValidate: {
      Type: Boolean,
      default: true
    },
    parentValidation: Boolean
  },

  data() {
    return {
      isValid: true
    }
  },

  methods: {
    updateValidation() {
      if (this.shouldValidate) {
        this.isValid = this.parentValidation
        this.$emit('validated')
      }
    }
  },

  watch: {
    value: function() {
      if (!this.isValid) {
        this.updateValidation()
      }
    }
  }
}
</script>

<style lang="scss">
@import '~@/styles/core/settings';

.pk-input-text {
  margin-bottom: 10px;

  .form__input {
    margin-bottom: 0px;

    &::placeholder {
      color: map-get($colors-util, 'muted');
      -webkit-text-fill-color: map-get($colors-util, 'muted');
      font: {
        size: 0.9rem;
        family: $font-regular;
        weight: bold;
      }
    }
  }
}
</style>
