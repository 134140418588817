<template>
  <div>
    <div class="modal-pk">
      <div class="modal">
        <header class="modal__header">
          <slot name="header">
          </slot>
        </header>

        <section class="modal__body">
          <slot name="body">
          </slot>
        </section>

        <footer class="modal__footer">
          <slot name="footer">
          </slot>
        </footer>
      </div>
    </div>
    <div class="modal-backdrop" v-on:click="close"/>
  </div>
</template>

<script>
export default {
  name: 'pkmodal',
  props: {
    close: Function
  }
}
</script>

<style lang="scss" scoped>
.modal-pk {
  position: fixed;
  top: 50%;
  bottom: 50%;
  left: 50%;
  right: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 11;

  .modal {
    border-radius: 16px;
    background: {
      color: #fff;
    };
  }
}

.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}
</style>
