import FasterEvent from '../fasterEvent'

export default new FasterEvent(
  { id: 'iap_request', revision: 3 },
  (eventBuilder, parameters) => {
    return eventBuilder
      .dimension('requestId', parameters.requestId)
      .dimension('skuName', parameters.skuName)
      .dimension('value', parameters.value)
      .dimension('currency', parameters.currency)
      .dimension('billingPlatform', parameters.billingPlatform)
      .build()
  }
)
