<template>
    <div>
        <img src="@/assets/logo.svg" class="logo" alt="logo">
    </div>
</template>

<script>
export default {
  name: 'logo'
}
</script>

<style lang="scss">

.logo {
  width: 100%;
  height: 100px;
}
</style>
