<template>
  <div class="stamp stamp--success">
    <img src="@/assets/success-check.svg" class="stamp__icon">
    <p class="stamp__text" v-t="'general.success'" />
  </div>
</template>

<script>
export default {
  name: 'success-stamp'
}
</script>
