import { EventBuilder } from 'faster-sdk-js'
import defaultEventParameters from './events/defaultEventParameters'

export default class FasterEvent {
  constructor(eventData, addDimensions) {
    this.id = eventData.id
    this.revision = eventData.revision
    this._addDimensions = addDimensions
  }

  build(parameters) {
    const eventBuilder = new EventBuilder(this.id, this.revision)

    var defaultParameters = defaultEventParameters.getDefaultParameters()
    Object.entries(defaultParameters).forEach(entry => {
      eventBuilder.dimension(entry[0], entry[1])
    })

    return this._addDimensions(eventBuilder, parameters)
  }
}
