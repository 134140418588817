import { version } from '../../../package.json'
import analytics from '../analytics'

class DefaultEventParameters {
  getDefaultParameters() {
    var deviceInfo = analytics.deviceInfo
    return {
      deviceType: 'Unknown',
      currentScreen: '',
      previousScreen: '',
      sourceAppMarket: 'Unknown',
      appVersion: version,
      timezone: deviceInfo.timezone,
      manufacturer: deviceInfo.manufacturer || 'Unknown',
      systemLocale: deviceInfo.language,
      platform: deviceInfo.platform,
      systemOsName: deviceInfo.system,
      systemOsVersion: deviceInfo.systemVersion,
      internetStatus: '',
      appLocale: navigator.language.substring(0, 2)
    }
  }
}

export default new DefaultEventParameters()
