import FasterEvent from '../fasterEvent'

export default new FasterEvent(
  { id: 'screen', revision: 4 },
  (eventBuilder, parameters) => {
    return eventBuilder
      .dimension('screenName', parameters.screenName)
      .dimension('sequence', parameters.sequence)
      .dimension('orientation', parameters.orientation)
      .build()
  }
)
