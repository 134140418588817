<template>
  <div class="box-form" :class="{'box-form--success': state.isSuccess, 'box-form--error': state.isError, 'box-form--borderless': borderless}">
    <slot name="header"></slot>

    <h1 class="box-form__title" align="center" v-t="title" />
    <p class="box-form__subtitle" :class="{'box-form__subtitle--success': state.isSuccess, 'box-form__subtitle--error': state.isError}" v-t="subtitle"></p>

    <slot name="success" v-if="state.isSuccess">
      <success-stamp />
    </slot>

    <slot name="fail" v-else-if="state.isError">
      <form class="form">
        <pkbutton :name="'general.tryAgain'"/>
      </form>
    </slot>

    <slot name="default" v-else/>
  </div>
</template>

<script>
import Pkbutton from '@/components/PKButton'
import SuccessStamp from '@/components/SuccessStamp'

export default {
  name: 'boxGeneric',
  props: {
    state: {
      type: Object,
      isSuccess: Boolean,
      isError: Boolean,
      default: function () {
        return { isSuccess: false, isError: false }
      }
    },
    title: String,
    subtitle: String,
    borderless: Boolean
  },
  components: {
    Pkbutton,
    SuccessStamp
  }
}
</script>
