
import FasterEvent from '../fasterEvent'

export default new FasterEvent(
  { id: 'iap_completed', revision: 4 },
  (eventBuilder, parameters) => {
    return eventBuilder
      .dimension('requestId', parameters.requestId)
      .dimension('skuName', parameters.skuName)
      .dimension('value', parameters.value)
      .dimension('currency', parameters.currency)
      .dimension('billingPlatform', parameters.billingPlatform)
      .dimension('success', parameters.success)
      .dimension('failureReason', parameters.failureReason)
      .build()
  }
)
