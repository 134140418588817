export default class AuthorizationHeaderTransformer {
  constructor(store) {
    this._authentication = {
      createUserSession: () => store.dispatch('authentication/createUserSession'),
      getUserSessionToken: () => store.getters['authentication/getUserSessionToken']
    }
  }

  async transform(headers) {
    let userSessionToken = this._authentication.getUserSessionToken()
    if (!userSessionToken) {
      await this._authentication.createUserSession()
      userSessionToken = this._authentication.getUserSessionToken()
    }

    headers.set('Authorization', `Bearer ${userSessionToken}`)
    return headers
  }
}
