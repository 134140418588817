import Faster, { FasterConfiguration } from 'faster-sdk-js'
import events from './events'
import config from '../config'

const { applicationKey, applicationSecret, appVersion } = config
const configuration = new FasterConfiguration(applicationKey, applicationSecret, { appVersion })

Faster.configure(configuration)
class FasterWrapper {
  constructor() {
    this.analytics = null
  }

  start() {
    this.analytics = Faster.start()
    this.events = events
  }

  get deviceInfo() {
    if (this.analytics == null) {
      return
    }

    return this.analytics.device.get()
  }

  get geo() {
    if (this.analytics == null) {
      return
    }

    return this.analytics.geo
  }

  emit(id, parameters) {
    if (this.analytics == null) {
      return
    }

    const event = this.events[id].build(parameters)
    this.analytics.event.register(event)
  }
}

export default new FasterWrapper()
